import './gallery.css';

const GalleryCard = (props) => {

    return ( 
        <div className="gallery-item">
        <img src = {`/gallery-img/${ props.galleryImageUrl }`} alt='reda 2023 gallery' className='img-fluid gallery-image ' />
        </div>
     );
}
export default GalleryCard;