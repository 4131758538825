import annivesaryLogo from '../../images/anniversary-logo.png';
import tmlogo from '../../images/tmlogo.png';
import './header.css';   

function Header() {
    return (
    <header className="">
      <div className='header-group container'>

        <div className='header-item'>
        <div className='event-company'><img src = {tmlogo} className='img-fluid tmlogo' alt='Thinkmint Logo' /></div>
        <div className='event-name'>REDA2024 - <br /> REDA 5TH YEAR</div>
        <div className='event-theme'>Navigating Change in Real Estate for a New Era</div>
        </div>

        <div className='header-item'>
        <img src={ annivesaryLogo } alt="Thinkmint group logos" className='anni-logo img-fluid'/>
        </div>

      </div>

      <div className='header-base-wrapper'>
      <div className='header-base-group container'>
          <div className='event-timeline'>
            <div className='event-date'><span className='yellow-accent'>Date: </span> 17th and 18th September, 2024 </div>
            <div className='event-time'><span className='yellow-accent'> Time: </span> 9:00AM Daily</div>
            <div className='event-venue'><span className='yellow-accent'>Venue: </span> Eko Hotel and Suites, Victoria Island, Lagos</div>
          </div>

          <div className='header-buttons'>
            <a href="https://mailchi.mp/thinkmint.ng/reda2024" className='header-button'>Get Exhibition Access</a>
            <a href="/register" className='header-button'>Register to Attend</a>
            <a href="https://bit.ly/Reda2023PostEventReport" className='header-button'>REDA23 Post Event Report</a>
            <a href="https://bit.ly/2024-sponsorship-brochure" className='header-button'> Sponsorship Brochure</a>
          </div>
      </div>
      </div>


    </header>

    )
}
export default Header