import React, { useEffect, useState } from 'react';
import SpeakerCard from './SpeakerCard';
import speakersData from '../../data/index.js';
import './speaker.css';


const SpeakerList = ({ data }) => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    if (speakersData.length > 0) {
    setSpeakers(speakersData);
    }
  }, []);

  return (
    <div className='speaker-group'>
     { speakers.map((speaker) => (
        <SpeakerCard
        key={speaker.id}
        id = {speaker.id}
        name={speaker.name}
        title={speaker.title}
        position={speaker.position}
        company={speaker.company}
        imageUrl={speaker.imageUrl}
        about={speaker.about}
        status = {speaker.status}
        />
    ))} 
    </div>
  );
}
 
export default SpeakerList;