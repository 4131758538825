import './about.css';
import Subtheme from '../subtheme/Subtheme';
// import aboutImage from '../../images/about-image.png';
import Reda23Video from '../video/Reda23Video';
import TicketBanner from '../ticketbanner/TicketBanner';
import VoteBanner from '../votebanner/VoteBanner';

const AboutUs = () => {
    return ( 
        <section className='about-us'>
            <div className='section-header'>
                <h2>About REDA</h2>
            </div>

            <VoteBanner />
            
            <div className='container about-reda-group'>

                <div className='about-reda-item'>
                The Real Estate Discussions and Awards (REDA) is a two day conference, exhibition and award ceremony organized by Thinkmint Nigeria. The event has proven to be the region’s flagship real estate event gathering the most senior investors, developers, operators and professionals, providing the perfect platform to transact across the region, as well as celebrate excellenceamongst real estate practitioners , with the maiden conference held virtually last year. This year's event themed Navigating Change in Real Estate For A New Era will be centered on discussions, bespoke networking opportunities and learnings to further improve real estate trends and transactions in the region across the global real estate market.

                <div className='about-point'> It seeks to do this through:
                    <ul>
                        <li>Highlighting the parts and bits of the industries (the governments of the countries, the policies and laws guiding the industries, public and private investors, the increasing populace etc.), evaluating the growth attained through their impact and seeking out areas in these parts which require invigoration.
                        </li>
                        <li>
                        Exploring methods of harnessing technological processes for demand and supply awareness, investments as well as economic growth
                        </li>
                        <li>
                        Evaluating the current state of the general real estate market and sharing views that may strengthen and increase its levels.
                        </li>
                    </ul>
                </div>
                <div className='about-reda-item'>
                    We expect to host 50 industry leading speakers. Over 1,000 onsite attendees and 10,000 online audience , consisting of local and international stakeholders including real estate developers, Investors, Government authorities, media personnel.
                </div>
                </div>
                <div className='about-video about-reda-item'> 
                    <Reda23Video />
                </div>
            </div>

            <div className='about-subtheme container'>
                <h3>Event Subtheme</h3>
                <Subtheme />
            </div>

            <TicketBanner />

        </section>
     );
}
 
export default AboutUs;