import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react'
import './speaker.css';

const SpeakerCard = (props) => {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.about}</Modal.Body>
            <Modal.Footer>
            <Button variant="warning" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    
        <div className="speaker-link" onClick={handleShow}>
        <img src = {`/speakers-img/${ props.imageUrl }`} alt= { props.name } className='speaker-image' />
        <h3 className = "speaker-title"> {props.name}</h3>
        <p className = "speaker-position"> {props.position}</p>
        <p className = "speaker-company"> {props.company}</p>
        <p className = "speaker-status"> {props.status}</p>
        </div>


        </div>
     );
}
 
export default SpeakerCard;
