import SpeakerList from "./SpeakerList";
import './speaker.css';
import VoteBanner from '../votebanner/VoteBanner';

const Speakers = () => {
    return (
    <div className="speaker">
        <div className='section-header'>
        <h2>Reda2024 Speakers</h2>
        </div>
        <div className="container">
        <SpeakerList />
        </div>
        <VoteBanner />
    </div>
    );
}
 
export default Speakers;