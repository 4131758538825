import './award.css';
import awardplaque  from '../../images/redaawardplaque.png'


const AwardList = () => {
    return ( 
        <section id='awardcenter'>
        <div className='section-header'>
          <h2>REDA 2024 Award Categories</h2>
        </div>
        <div className="reda-award-group container">

            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Woman of the Year</div>
                    <div className='reda-award-nominee'>
                        <div>Saadiya Aliyu, CEO, Urban Shelter</div>
                        <div>Uzo Oshogwe, CEO, Afriland Properties </div>
                        <div>Adesuwa Ladojo, Managing Director/CEO, Lekki Free Trade Zone </div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Man of the Year</div>
                    <div className='reda-award-nominee'>
                        <div>Paul Onwuanibe, CEO, Landmark Africa</div>
                        <div>Engr Bolaji Adebanjo, MD/CEO, MGE Realty Limited </div>
                        <div>Kola Ashiru-Balogun, CEO, Terra Developers </div>
                        <div>Olaide Agboola, CEO, Purple Capital</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Young Real Estate Person of the Year</div>
                    <div className='reda-award-nominee'>
                        <div>Dr Oluwatosin Olatujoye, GCEO, Zylus Group Intl</div>
                        <div>Dr Muibi Kehinde Hammed, CEO MKH Properties /Chairman REDAN Oyo State Chapter</div>
                        <div>Ayobami Akindipe, CEO, Ace Real Estate Development Limited</div>
                        <div>Dolapo Omidire</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Property Development Company of the Year - Residential</div>
                    <div className='reda-award-nominee'>
                        <div>MGE Realty </div>
                        <div>Afriland properties</div>
                        <div>Homework Group</div>
                        <div>Mixta Africa</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Property Development Company of the Year - Commercial</div>
                    <div className='reda-award-nominee'>
                        <div>Afriland properties</div>
                        <div>Dayspring Property Development Company</div>
                        <div>Purple Group</div>
                        <div>Urban Shelter</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Property Development Company of the Year - Retail</div>
                    <div className='reda-award-nominee'>
                        <div>Landmark Africa</div>
                        <div>Purple Group</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Luxury Project of the Year (Offplan) </div>
                    <div className='reda-award-nominee'>
                        <div>Skyvilla(Palton Morgan Holdings)</div>
                        <div>Empire Vogue (Deluxe Residences)</div>
                        <div>The Nexus</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Company of the Year (Site and Service) </div>
                    <div className='reda-award-nominee'>
                        <div>MKH Investments </div>
                        <div>Zylus group</div>
                        <div>Ace Real Estate and Development Company</div>
                        <div>Novarick Homes </div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Service Company of the Year </div>
                    <div className='reda-award-nominee'>
                        <div>Knight Frank </div>
                        <div>CBRE|Excellerate</div>
                        <div>CW Real Estate</div>
                        <div>Ubosi Eleh & Co</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Research & Advisory Company of the Year </div>
                    <div className='reda-award-nominee'>
                        <div>Northcourt Real Estate </div>
                        <div>Broll</div>
                        <div>CBRE | Excellerate</div>
                        <div>Estate Intel</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Architecture and Design company of the Year </div>
                    <div className='reda-award-nominee'>
                        <div>ACCL</div>
                        <div>CCP</div>
                        <div>ECAD Architects</div>
                        <div>SAOTA</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Facility Management Company of the Year </div>
                    <div className='reda-award-nominee'>
                        <div>Fieldco</div>
                        <div>Alpha Mead Facility Management Company</div>
                        <div>Global PFI Group</div>
                        <div>Provast</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>
            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">PropTech company of the year </div>
                    <div className='reda-award-nominee'>
                        <div>Jointly </div>
                        <div>Cutstruct Technology</div>
                        <div>Labor Hack</div>
                        <div>Rebar Perfecta</div>
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>

            <div className="reda-award-item">
                <img src={ awardplaque } alt="Avatar" className="image img-fluid" />
                <div class="reda-award-category">
                    <div class="reda-award-title">Real Estate Company of the Year (Top Emergent series)</div>
                    <div className='reda-award-nominee'>
                        <div>Elanorris Real Estate </div>
                        <div>Ace Real Estate and Development Company </div>
                        <div>Hontar Projects </div>
                        <div>Facibus Housing </div>
                        <div>Edala Homes </div>
           
                    </div>
                    <a href='https://forms.gle/Cc8n4z7zcePQCQSU8' className='reda-vote-button'>Vote Now</a>
                </div>
            </div>


        </div>
        </section>

    );
}
 
export default AwardList;