import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    const date = new Date()
    const getYear = date.getFullYear()

    return (
        <footer>
            <div className='footer-group container'>

            <Link to='/' className='footer-logo'>
                REDA2024
            </Link>

            <div className='footer-item footer-link-group'>
                <div className='footer-item-title'>Useful Links</div>
                <Link to='/about' className='footer-link-item' >About</Link>
                <Link to='/speakers-list' className='footer-link-item' >Speakers</Link>
                <Link to='/agenda' className='footer-link-item'>Agenda</Link>
                <Link to='/register' className='footer-link-item' >Register</Link>
                <Link to='/tickets' className='footer-link-item' >Get Conference Ticket</Link>
                <Link to = '/gallery' className='footer-link-item'> Gallery</Link>
                <Link to = '/award-categories' className='footer-link-item'>Award Categories</Link>
            </div>

            <div className='footer-item'>
                <div className='footer-item-title'>Contacts</div>
                <div className="footer-contact">0809 784 5065</div>
                <div className="footer-contact">0706 481 9670</div>
                <div className="footer-contact">0911 293 0412</div>
                <div className="footer-contact">conference@thinkmint.ng</div>
            </div>

            </div>
            <hr className='footer-hr'></hr>

            <div className="footer-base">© Copyrights { getYear } All rights reserved. < a href='https://www.thinkmint.eu'> Thinkmint.eu </a> </div>
        </footer>
    );
}
 
export default Footer;
