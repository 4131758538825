import './gallery.css';
import GalleryCard from './GalleryCard';
import galleryData from '../../data/galData.js';

const GalleryList = () => {

    const gal = galleryData.map((gallery) => {

        return <GalleryCard
        id = {gallery.id}
        key = {gallery.id}
        galleryImageUrl = {gallery.galleryImageUrl}
        />
    })
   
    return ( 
        <section className='gallery-section'>
            <div className='section-header'>
                <h2>REDA Gallery</h2>
            </div>
            <div className='container gallery'>
            { gal }
            </div>
        
        </section>
    );
}
 
export default GalleryList;

