const speakersData  = [
    {
        id : 1,
        name : "Imo-Abasi Jacob, Snr",
        position : "Chairman/MD,",
        company : "IBOM AIR/Akwa Ibom Investment Corporation",
        imageUrl : "imo-abasi.png",
        about : "Imo-Abasi is presently the Chairman, Board of Directors of Ibom Airlines Limited (Ibomair). He is also the Managing Director/CEO, Akwa Ibom Investment Corporation, and has served as the Executive Director (Operations), in charge of entrepreneurship & Business Development, Project Management & Implementation and Investment Promotion & Appraisal.",
        status : "Guest Speaker"
    },
    {
        id : 2,
        name : "Akin Opatola (Keynote Speaker)",
        position : "President, FIABCI, Nigeria",
        company : "Founder and Principal Partner, Olawale Jordan & Company",
        imageUrl : "akin-opatola.png",
        about : "Akin Opatola is a Chartered Surveyor Property Valuer Internationally accredited Mediator, Real Estate Coach and the founder and Principal Partner at Olawale Jordan Company, a professional real estate brokerage and marketing firm. He is an alumnus of the Faculty of Environmental Sciences, Federal University of Technology, Minna and an Associate member of the Nigerian Institution of Estate Surveyors and Valuers. ",
        status :  "Keynote Speaker"
    },
    {
        id : 3,
        name : "Tari Taylaur",
        position : "Senior Special Assistant",
        company : "Lagos State Government on Environment",
        imageUrl : "tari-taylaur.png",
        about : "Tari Taylaur is the Senior Special Assistant to the Lagos State Governor on Environment. The core focus of her office is to generate community and Stakeholder participation towards a clean and sustainable Lagos.",
        status :  ""
    },
    {
        id : 4,
        name : "TPL, Kunle Salami ",
        position : "Director of Physical Planning,",
        company : "Lagos State",
        imageUrl : "kunle-salami.png",
        about : "TPL Salami Adekunle is a chartered and experienced Town Planner with over 28 years post-cognate experiences spanning Private and Public Sector Practices, Excellent reputation for solving Urban Development and Physical Planning as well as Urban Regeneration, Environmental and Human Resources issues and challenges.",
        status :  ""
    },
    {
        id : 5,
        name : "Ayodeji Jospeph",
        position : "Managing Director / CEO",
        company : "Lagos State Development and Property Corporation (LSDPC)",
        imageUrl : "ayodeji-joseph.png",
        about : "The new Managing Director/CEO of Lagos State Development and Property Corporation (LSDPC), Hon. Ayodeji Joseph has resumed duty, pledging to increase the earnings of the Corporation. He also promised to place utmost importance on staff welfare, according to a statement issued by LSDPC’s Head Public Relations & Media, Sulayman Deji-Etiwe.",
        status :  ""
    },
    {
        id : 6,
        name : "Ibrahim A. Abdullahi",
        position : "Managing Director / CEO",
        company : "Nasarawa Investment & Development Agency (NASIDA)",
        imageUrl : "ibrahim-abdullahi.png",
        about : "Ibrahim Adamu Abdullahi is a Trade, Finance & Investment Lawyer with multifaceted advisory experience. He doubles as the Special Adviser to the Governor on Investment and Economic Planning. He holds a Bachelor of Laws from the University of Reading, United Kingdom, and initially practiced as a commercial lawyer in Dikko and Mahmoud (Solicitors & Advocates) and later with partners in DalaHill Law Practice. He has contributed immensely in shaping Nigeria’s PPP delivery framework and has participated in & supported a range of multi-sectorial transactions. Abdullahi spearheads the Nasarawa Ease of Doing Business Reform efforts. He also serves as the Head of the Governor’s Delivery Unit leading the delivery of the Nasarawa Economic Development Strategy (Governor Sule’s economic development plan) as well as Secretary of the Nasarawa Investment & Economic Advisory Council.",
        status :  ""
    },
    {
        id : 7,
        name : "Odunayo Ojo",
        position : "CEO",
        company : "UPDC",
        imageUrl : "ojo-odunayo.png",
        about : "Odunayo Ojo is currently the Chief Executive Officer & Executive Director at UPDC Plc and also holds the position of Vice President at Ocean & Oil Holdings Ltd. He is a Member of The Royal Institution of Chartered Surveyors, The Nigerian Institution of Estate Surveyors & Valuers, and the International Facilities Management Association. Prior to his current positions, he was the Chief Executive Officer at Alaro City from 2017 to 2021. He also held the position of Development Director at Laurus Development Partners Ltd.and Director-Development & Projects at Eagle Hills Properties LLC. Mr. Ojo obtained an MBA from the University of Lagos.",
        status :  ""
    },
    {
        id : 8,
        name : "Engr. Igbuan Okaisabor",
        position : "MD/CEO",
        company : "Construction Kaiser",
        imageUrl : "igbuan-okaisabor.png",
        about : "Igbuan Okaisabor has extensive work experience in various leadership roles. Igbuan served as the CEO of Construction Kaiser Limited (CKL) from 1993 to the present. Additionally, they have held positions such as President of the Board of Directors and Member of the Board of Directors at the Nigerian-German Chamber of Commerce since 2020. Igbuan was also a Member of the Governing Council at the Lagos State Employability Support Project starting in 2017.",
        status :  ""
    },
    {
        id : 9,
        name : "Ayuli Jemide",
        position : "Founder and Lead Partner",
        company : "Detail Commercial Solicitors",
        imageUrl : "ayuli-jemide.png",
        about : "Ayuli Jemide (AJ) is a model contrarian who finds excitement in championing concepts that go against the grain. He is the founder and lead Partner of DETAIL Commercial Solicitors, Nigeria’s first commercial solicitors’ firm to specialize exclusively in non-court room practice.",
        status :  ""
    },
    {
        id : 10,
        name : "Wilson Erumebor",
        position : "Senior Economist and Manager",
        company : "The Nigerian Economic Summit Group",
        imageUrl : "wilson.png",
        about : "Wilson Erumebor is a senior economist at the Nigerian Economic Summit Group (NESG). His areas of interest and expertise include macroeconomic research, industry research, structural transformation, and poverty studies. His latest piece on Nigeria’s quest for economic inclusion was published in February 2023 by the Brookings Institution, a globally recognised think-tank.",
        status :  ""
    },
    {
        id : 11,
        name : "Jide Adekola",
        position : "Group Managing Director",
        company : "Homework Group Africa",
        imageUrl : "jide-adekola.png",
        about : "Jide Adekola is one of the co-founders of the company, A registered Architect and member of the Nigeria institute of Architects (NIA), he has gathered years of hands of experience in Architectural Consultancy , Project Management and construction with some of the topmost Architectural and Real Estate firms in the country. With relevant qualification in Risk Management, Prince 2 at practitioner level and Health and safety Management in Construction, he also holds master degrees in both Property Development from Birmingham city University, UK and Architecture from University of Lagos. Jide has worked as project Architect at Adeniyi Coker Consultants Ltd. (ACCL), Head of projects at Crown Realties Plc and Manager planning and commercial development at Legacy Realties Ltd.",
        status :  ""
    },
    {
        id : 12,
        name : "Wayne Berger",
        position : "CEO-Co-Founder",
        company : "Ishack Ventures/Africa Prop-Tech Forum",
        imageUrl : "wayne-berger.png",
        about : "Wayne is experienced technology entrepreneur, specialising in PropTech & FinTech platforms and experienced in working with multi-cultural, decentralised innovation teams. Wayne currently holds the position of Managing Partner of iShack Ventures, a Venture Builder investing in disruptive technologies in the PropTech, FinTech and EduTech sectors.",
        status :  ""
    },
    {
        id : 13,
        name : "Jamila Faniyi",
        position : "Managing Director",
        company : "M&E Kaiser Limited",
        imageUrl : "jamila-faniyi.png",
        about : "Jamila holds a Bachelor Degree in Building from Ahmadu Bello University, Zaria, Masters Degree in Construction Management from the University of Lagos with lots of trainings both locally and internationally including Harvard Business School, Boston and Lagos Business School.",
        status :  ""
    },
    {
        id : 14,
        name : "Abiodun Mamora",
        position : "Executive Director",
        company : "Finance Construction Kaiser",
        imageUrl : "abiodun-mamora.png",
        about : "Abiodun is a C-suite executive with experience in business transformation and financial performance improvement. Her skills, board experience, and industry expertise are valuable in advancing corporate strategy and driving high performance in organizations. She currently serves as the Executive Director – Finance for Construction Kaiser Limited (CKL). ",
        status :  ""
    },
    {
        id : 15,
        name : "Babatope Davies",
        position : "Vice President",
        company : "Investment Banking, Stanbic IBTC",
        imageUrl : "babatope-davies.png",
        about : "Babatope Davies, based in Nigeria, is currently a Vice President at Stanbic IBTC, bringing experience from previous roles at FCMB Capital Markets Limited, First City Monument Bank Limited and Radix Capital Partners Limited. Babatope Davies holds a 2002 - 2007 Bachelor's degree in Accounting and Finance @ Lagos State University. With a robust skill set that includes Project Finance, Financial Advisory, Financial Modeling, Structured Finance, Valuation and more, Babatope Davies contributes valuable insights to the industry.",
        status :  ""
    },
    {
        id : 16,
        name : "Oguche Agudah",
        position : "CEO",
        company : "Pension Funds Operators Association of Nigeria (PenOp)",
        imageUrl : "oguche.png",
        about : "Oguche is currently the Chief Executive Officer (CEO) of the Pension Fund Operators Association of Nigeria (PenOp). In this role he leads the efforts of Nigeria’s pension industry in engaging regulators, the government, the media, financial markets and other stakeholders in order to ensure that the interests of the pension operators are protected and promoted and also in ensuring that the pension industry has a positive effect on National Development.",
        status :  ""
    },
    {
        id : 17,
        name : "John Oamen",
        position : "CEO,",
        company : "CutStruct Technology Limited",
        imageUrl : "john-oamen.png",
        about : " John Oamen, is a visionary leader who is determined to transform the construction industry in Nigeria using innovative technology and data-driven solutions. As the Co-founder and CEO of CutStruct Technology Limited, John is on a mission to promote transparency and eliminate inefficiencies in the construction industry, paving the way for a brighter, more sustainable future. ",
        status :  ""
    },
    {
        id : 18,
        name : "Ubong Ita",
        position : "CEO, Co-Founder",
        company : "Kobo Accountant Africa",
        imageUrl : "ubong-ita.png",
        about : "Ubong is a seasoned IT professional, Accounting/Auditing professional, financial literacy specialist, and trainer with a strong flair for numbers, technology, and systems designs/optimization.",
        status :  ""
    },
    {
        id : 19,
        name : "Onome Umukoro",
        position : "Group Head, Marketing and Communications",
        company : "Mixta Africa",
        imageUrl : "onome-umukoro.png",
        about : "Onome Umukoro is a seasoned marketing and corporate communications expert based in Lagos, Nigeria. With extensive experience managing teams across African regions, Onome excels in brand outreach, events sponsorship, marketing management, and business development. She has a proven track record in boosting brand awareness, generating leads and sales, and implementing cost-saving strategies.",
        status :  ""
    },
    {
        id : 20,
        name : "Gbenga Adigun",
        position : "Founder & Co-CEO",
        company : "Rebar Perfecta",
        imageUrl : "gbenga-adigun.png",
        about : "Gbenga Adigun started his interesting career in the Banking & Finance space where he spent about 12 years. His Banking & Finance experience started with Guaranty Trust Bank and Nigeria’s largest non-bank Financial Institution, ARM. In 2020, he also went on to assist in floating a Fintech in the Asset Management space, called Zimvest.",
        status :  ""
    },
    {
        id : 21,
        name : "Sean Godoy",
        position : "CEO",
        company : "Proptech Africa",
        imageUrl : "sean-godoy.png",
        about : "Sean is a property professional who has spent over a decade working in various sectors of the property industry in both South Africa and the UK. Most of his professional experience has been as a property market analyst and commentator. In 2017 he founded Divercity Property Solutions which offers a range of advisory and training solutions to the property industry in South Africa, as well as other parts of Sub Saharan Africa. He is a proptech commentator and enthusiast, and is cofounder of the SA Proptech and Proptech Africa associations. He is also an exco member at the SA Chamber of Commerce in the UK.",
        status :  ""
    },
    {
        id : 22,
        name : "ESV Tolu Dima-Okojie",
        position : "Estate Surveyor & Valuer",
        company : "Kola Akomolede & Company ",
        imageUrl : "tolu-dima.png",
        about : "As a certified Real Estate Surveyor & Valuer, she is the Managing Partner at Kola Akomolede & Co. a seasoned property-consulting firm with Head Quarters in Victoria Island, Lagos. She is a seasoned professional with over a decade experience in the strategic assessment, redevelopment and project management of real estate assets, to optimize clients’ investment goals. Her firm has over 40 Billion worth of assets under management for clients home and abroad.",
        status :  ""
    },
    {
        id : 23,
        name : "Amb. Dr. Sanmi Oluwasile",
        position : "Founder",
        company : "Sustainable Green Environment initiative",
        imageUrl : "sanmi-oluwasile.png",
        about : "Dr. Sanmi is a Sustainable Professional, Green Ambassador, MEP Cost Expert, LEED for Cities and Communities Pro, Green Building Consultant, and Climate/Carbon Expert. He is the Founder and current Chairman of the Board of Sustainable Green Environment Initiative, the President of the African Network of Green Ambassadors, Nigeria Host Partner of Global WE Program for Museum for the United Nations (UN Live), the Managing Director of Construct Green Consult LLC, Nigeria Country Director for Foundation for Climate Restoration USA, the proud convener of Certified Sustainable Business Global Conference and the Green Ambassadorship Fellowship Programme, he is also the Co-Chair & Technical Lead, Training and Certification Team and also Chairman, Summit Technical Committee at Green Building Council Nigeria.",
        status :  ""
    },
    {
        id : 24,
        name : "Osarogie Edo-Osagie",
        position : "Design Associate",
        company : "Mixta Africa",
        imageUrl : "osarogie.png",
        about : "Osarogie Edo-Osagie is a seasoned built environment professional with a proven track record of driving excellence and sustainability in the construction industry. With an academic background in Architectural Environment Engineering and Renewable Energy from the University of Nottingham, Osarogie has honed a diverse skill set spanning project management, design optimization, and sustainable practices. His experience in top-tier companies like Mixta Africa Real Estate Plc and Julius Berger Nigeria PLC has equipped him with a comprehensive perspective on addressing the challenges of climate change and risk management in the construction sector. As a dedicated advocate for sustainable building initiatives, Osarogie has played a crucial role in research on sustainable construction methods and coordinated green building certification for Mixta Africa's Real Estate Pilot Sustainable Residential Development Marula Park which obtained Preliminary EDGE certification in Q1 2024.",
        status :  ""
    },
    {
        id : 25,
        name : "Olubisi Shaola",
        position : "Founder",
        company : "Finn Grey Projects",
        imageUrl : "olubisi-shaola.png",
        about : "Olubisi Shaola, a trailblazing entrepreneur hailing from Lagos, Nigeria, embarked on his professional journey as a distinguished property lawyer. Armed with a deep understanding of the legal intricacies in the industry, he soon transitioned into the realm of real estate development, driven by a vision to revolutionize the market dynamics.With a keen eye for innovation and a passion for social impact, Olubisi specializes in crafting premium and luxury residences that are not only aesthetically appealing but also financially accessible to the burgeoning middle class. His commitment to bridging the gap between luxury living and affordability has garnered acclaim and transformed the real estate landscape. As a visionary leader and catalyst for change, Olubisi Shaola continues to redefine traditional paradigms in real estate, championing inclusivity, sustainability, and excellence in every endeavor he undertakes.",
        status :  ""
    },
    {
        id : 26,
        name : " Monsurat Mohammed",
        position : "Head",
        company : " Help-To-Own Fund Family Homes Funds",
        imageUrl : "monsurat-mohammed.png",
        about : "Monsurat is a strategic and highly adaptable global leader with 16 years of experience across Micro, SME, Housing and Development Finance within the Nigerian Financial Service Industry with excellent communication and analytical skills, effective business management and stakeholder management. A strong advocate of collaborative leadership focused on building and leading high performing teams while cultivating a culture of professionalism with a result-oriented mindset.",
        status :  ""
    },
    {
        id : 27,
        name : " Olukayode Odebiyi",
        position : "Director, Property Development",
        company : "Afriland Properties",
        imageUrl : "olukayode-odebiyi.png",
        about : "Kayode is a consummate professional with over 23 years of work experience cutting across Real Estate & Portfolio Management, Strategy Development, Finance & Treasury Management, and Business Development. He has functioned in various roles in his career, amassing knowledge in Analysis, Project management, and Design & Building Efficiency.",
        status :  ""
    },
    {
        id : 29,
        name : " Adeola Olusodo",
        position : "Executive Director Joint Venture & Business Development",
        company : "LSDPC",
        imageUrl : "adeola-olusodo.png",
        about : "Adeola Olusodo is a product of Igbobi College Yaba and Kings College Lagos. He is also an alumnus of the prestigious University of Lagos with a Masters degree in Business Administration and another in Housing Development & Management. His work experience spans over three decades in both private and public sector organizations including Chellarams PLC, Daewoo Motors and LSDPC.",
        status :  ""
    },
    {
        id : 30,
        name : "Olisa Umerah",
        position : "CEO & Co-Founder",
        company : "Jointly Proptech Inc",
        imageUrl : "olisa-umerah.png",
        about : "Olisa Umerah is a visionary leader determined to transform how Africans use and invest in real estate. As a serial entrepreneur and the CEO & Co-Founder of Jointly Proptech Inc., he is on a mission to help Africans build wealth through innovative, data-driven real estate solutions.",
        status :  ""
    },
    {
        id : 31,
        name : "Omosalewa Fajobi",
        position : "Executive Director, Chief Operating Officer",
        company : "Misa Nigeria Limited",
        imageUrl : "omosalewa-fajobi.png",
        about : "Omosalewa Fajobi works in a full-time capacity as the Executive Director, Chief Operating Officer in Misa Nigeria Limited, a property development company which offers a wide range of services from construction to property and facilities management as well as real estate agency.",
        status :  ""
    },
    {
        id : 32,
        name : "Habinuchi Owhondah",
        position : "Country Manager,",
        company : "CBRE|Excellerate Nigeria Limited",
        imageUrl : "habinuchi-owhondah.png",
        about : "Habinuchi is a proficient real estate professional with rounded competence across facilities management, corporate real estate advisory, transaction management and project management functions. He possesses almost two decades of experience working across multiple geographies in Sub-Saharan Africa and different industry sectors driving revenue growth and enhancing portfolio value through innovative client focused solutions.",
        status :  ""
    },
    {
        id : 33,
        name : "Richard Deniran",
        position : "Chief Operating Officer,",
        company : "MGE REALTY",
        imageUrl : "richard-deniran.png",
        about : "With over a decade of experience in sales and marketing, particularly in the Real Estate off-plan project sector, Richard brings a wealth of knowledge and expertise to the table. Having operated primarily in the Lagos/Abuja markets of Nigeria, Richard has attracted a diverse client base from across the globe, including Nigerians in the diaspora. His tenure in senior management positions has equipped him with the ability to manage large sales structures and lead projects from inception to completion. Notably, he played a pivotal role in the development of Iconic Towers, Victoria Island, and other notable projects.",
        status :  ""
    },
    {
        id : 34,
        name : "Ifeoluwa Akinteye",
        position : "Associate,",
        company : "Wigwe & Partners",
        imageUrl : "ifeoluwa-akinteye.png",
        about : "Ifeoluwa Akinteye is a multi-jurisdictional trained legal practitioner and a dispute resolution counsel at the Law firm of Wigwe & Partners. He manages a portfolio of disputes within the real estate and construction, debt recovery, banking, oil and gas, shipping and admiralty practice areas. Within 6 years in the advocacy space, Ifeoluwa has actively met both private and corporate clients’ objectives with over 10 judgments of Superior Courts of Records and arbitration awards to his involvement.",
        status :  ""
    },
    {
        id : 35,
        name : "Chukwuma Harrison Okoye",
        position : "CEO",
        company : "FACIBUS Housing",
        imageUrl : "chukwuma-okoye.png",
        about : "Chukwuka Harrison Okoye is a multiple award-winning CEO with Facibus Housing, a real estate development brand primarily based in Lagos, Nigeria. Equipped with a Master in Business Law from Duke University, USA, he offers a unique understanding of blending the science of buildings with business administrative finesse that's required to expand the growth and reach of Facibus Housing. Over the years, his work with Facibus Housing has enabled the brand to further bridge the gap between adequate and affordable housing in Lekki, Lagos.",
        status :  ""
    },
    {
        id : 36,
        name : "Folajimi Ibrahim",
        position : "Founder",
        company : "Cogent Properties",
        imageUrl : "folajimi-ibrahim.png",
        about : "Folajomi Ibrahim, popularly known on social media as Sir Folajomi is a visionary real estate developer and the founder of Cogent Properties, renowned for uncompromising excellence and integrity. By blending innovative construction practices with a respect for tradition, he sets new industry standards in quality and value.",
        status :  ""
    },
];

export default speakersData