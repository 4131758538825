import './video.css';

const Reda23Video = () => {
    return ( 
        <iframe
            // width="400"
            // height="280"
            src="https://www.youtube.com/embed/fXMdjoXdgqo?si=k4PtjUmiU89c_BGA"
            className='reda-video'
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe> 
    );
}
 
export default Reda23Video;