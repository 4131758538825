import './ticket.css';
import VoteBanner from '../votebanner/VoteBanner';

const Ticket = () => {
    return ( 
      <section>
        <div className='section-header'>
          <h2>REDA 2024 Tickets</h2>
        </div>

        <VoteBanner/>

        <div className="reda-ticket container">
        <div className='reda-ticket-intro'>Whether you are looking for long-term strategies or for the future, the ideas and visions of the speakers will inspire you and help you develop concrete measures to thrive in the real estate industry.
        </div>

       

        <div className="reda-ticket-group">

        <div className="reda-ticket-item">
          <div className="reda-ticket-body">
            <h3 className="regular">&#8358;50,000</h3>
            <hr></hr>
            <p> ☉ X1 Full Access to all sessions</p>
            <p> ☉ Access to networking events</p>
          </div>
          <a href="https://paystack.com/pay/reda2024"
          className='reda-ticket-button'> 
          Buy Ticket
          </a>
        </div>

        <div className="reda-ticket-item">
          <div className="reda-ticket-body">
            <h3 className="standard">&#8358;100,000</h3>
            <hr></hr>
            <p> ☉ X1 Full Access to all sessions</p>
            <p> ☉ Access to networking events</p>
            <p> ☉ Certificate of attendance</p>
            <p> ☉ Access to award ceremony</p>
          </div>
          <a href="https://paystack.com/pay/reda2024standardticket"
          className='reda-ticket-button'> 
          Buy Ticket
          </a>
        </div>

        <div className="reda-ticket-item">
          <div className="reda-ticket-body">
            <h3 className="vip">&#8358;150,000</h3>
            <hr></hr>
            <p> ☉ X1 Full Access to all sessions</p>
            <p> ☉ Access to networking events</p>
            <p> ☉ Certificate of attendance</p>
            <p> ☉ Access to award ceremony</p>
            <p> ☉ Company profile &#38; logo on event brochure and media communications </p>
            <p> ☉ Certificate in Real Estate Marketing Master class powered by AD Dynamo </p>
            <p> ☉ Certificate in Navigating Property Sales Master class </p>
            <p> ☉ Certificate in Data Science & AI (13 weeks online course from the European Business Institute) </p>
          </div>
          <a href="https://paystack.com/pay/reda2024vip-res"
          className='reda-ticket-button'> 
          Buy Ticket
          </a>
        </div>

        <div className="reda-ticket-item">
          <div className="reda-ticket-body">
            <h3 className="six">&#8358;500,000</h3>
            <hr></hr>
            <p> ☉ X1 Full Access to all sessions</p>
            <p> ☉ Access to networking events</p>
            <p> ☉ Certificate of attendance</p>
            <p> ☉ Access to award ceremony</p>
            <p> ☉ Company profile &#38; logo on event brochure</p>
            <p> ☉ Display of company branded items</p>
          </div>
          <a href="https://paystack.com/pay/reda2024-tableforsix"
          className='reda-ticket-button'> 
          Buy Ticket
          </a>
        </div>


        </div>
        </div>

      </section>
    );
  }
         
export default Ticket;

       
