import Table from 'react-bootstrap/Table';
import './agenda.css';
import VoteBanner from '../votebanner/VoteBanner';

const Agenda = () => {

    const colTime = 1
    const colNum = 2
    
    return (
        <div className="agenda">
            <div className="section-header">
                <h2>REDA2024 Agenda</h2>
            </div>
            <div className='container'>
            <div className='day-1'>
                <h2 className='table-title'>Day 1 - Tuesday, September 17th, 2024</h2>
                <Table hover>
                <thead>
                    <tr>
                    <th colSpan={colTime}>Time</th>
                    <th colSpan={colNum}>Activities</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        8:00 AM - 10:00 AM
                        </div>
                        </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Registration / Investors Forun (Strictly by Invitation)
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:00 AM - 10:10 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                       Welcome Address
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                    <div className='table-time'>
                    10:10 AM - 10:25 AM
                    </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Opening Speech - Hon. Ayodeji Joseph MD/CEO LSDPC
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:25 AM - 10:45 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Keynote Speech by Special Guest of Honour
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:45 AM - 11:00 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Navigating Change Via Investments For The Growth of The Economy by GUEST SPEAKER - Imo-Abasi Jacob Snr (Chairman/MD, IBOMAIR/AKWA-IBOM INVESTMENT CORPORATION)
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:00 AM - 11:15 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                       Sponsor Presentation - MGE Realty
                        </div>
                    </td>
                    </tr>


                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:15 AM - 11:30 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                       Sponsor Presentation - Wigwe & Partners - Ifeoluwa Akinteye (Associate Wigwe & Partners)

                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={2}>
                        <div className='table-title'>
                        Planery 1
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:30 AM - 12:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>

                        <div className='table-title'>
                        The Role of Real Estate in institutional and Investment Portfolios - Is There Any Appeal?
                        </div>
                        <div className='table-content'>
                        <div className='table-title'> Session Objective</div>
                        <p> In the decade after the Global Financial Crisis (GFC), fixed income allocations declined. Commercial real estate was one of the beneficiaries as a recipient of those outflows.
                        </p>
                        <p> But the current interest rate hike cycle has increased the attractiveness of fixed-income strategies again, with institutional investors shifting their portfolio allocations towards government bonds, investment grade credit, high-yield corporate credit and private credit. </p>
                        <p>As examples: For allocations by sovereign wealth funds and private capital investors, fixed-income allocations have risen, while real estate, private equity and hedge fund allocations declined in 2023.</p>
                        <p>However, considering an institutional investor’s investment horizon and the nature of higher bond yields, being overweight to fixed income is a short-term strategy.</p>
                        <p>Even if a higher inflation or interest-rate era extends beyond 2025, real estate is better placed to offer inflation protection relative to bond investments due to its steady, stable income streams and mid- to long-term return outperformance. </p>
                        <p>Having established the above facts , these panel session would discuss areas to consider in the role of real estate in institutional and investment portfolios with key focus on:</p>

                        <ul>
                            <li>The Timeless Appeal of Real Estate Investments</li>
                            <li>Leverage and Financing Opportunities</li>
                            <li>Tax Advantages and Deductions</li>
                            <li>Numerator and Denominator Pressures</li>
                            <li>Diversification for Portfolio Stability</li>
                            <li>Hedge Against Inflation</li>
                            <li>Steady Cash Flow through Rental Income</li>
                        </ul>

                        </div>

                        <div className='table-title'>
                        The Role of Real Estate in institutional and Investment Portfolios - Is There Any Appeal?
                        </div>
                        <div className='table-content'>
                        <p></p>
                        <p><span className='table-title'>Moderator:</span> Ubong Ita ( Co-founder/CEO, Nobody Accountant)                        </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Ibrahim A.Abdullahi  (CEO, Nasarawa Invest)</li>
                        <li>Babatope Davies CFA, FCCA (Vice President, Investment Banking, Stanbic IBTC)</li>
                        <li>Abiodun Mamora (Executive Director Finance , Construction Kaiser)
                        </li>
                        <li>Wilson Erumebor	(Senior Economist &  ManagerThe Nigerian Economic Summit Group )</li>
                        <li>Omosalewa Fajobi (Executive Director/COO, Misa Nigeria Limited) </li>
                        <li>Ifeoluwa Akinteye (Associate | Wigwe & Partners) </li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        12:00 PM – 1:20 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Exhibition Opening/Tour Led by Special Guest of Honour, Guest Speaker, Keynote Speaker
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        1:20 PM – 2:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Networking Break/Master Class Real Estate Marketing - ALEPH(STRICTLY FOR PAID PARTICIPANTS)
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 2</div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:00 PM -  2:15 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Sponsor Presentation: Monsurat Mohammed (Head, Help-To-Own Mortgage,Family Homes Funds)
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:15 PM - 3:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Housing , Cost of living and its social impact
                        </div>

                        <div className='table-content'>
                        <div className='table-title'> Session Objective</div>
                        <p>
                        Nigeria has been sited to be among the countries with the  least affordable houses in the world in comparison to the per capita income of its citizens, the result of a persistent undersupply of houses. Unaffordable housing has far-reaching social and economic consequences – children and families living in below standard accommodations, declining home ownership, health problems from overcrowding, poor productivity, and lower living standards.
                        </p>

                        <p>
                        Housing affordability is arguably the single most pressing economic, social and cultural problem facing the Government. Housing is an essential human need and consumes a significant share of household income. Increasing housing supply and lowering housing costs will improve the living standards of all Nigerians. It will lift productivity and  wages by allowing more workers to live and work in cities. It will allow  more social housing, meaning fewer families living in below standard accommodations even in locations outside the big cities.
                        </p>
                        <p>
                        This panel session seeks to discuss ways to fix the housing crisis, with a focus on:
                        </p>
                        
                        <ul>
                            <li>The effect of housing supply and demand on affordability</li>
                            <li>Improving acts and schemes to make more land available for housing</li>
                            <li>Private , Public funding and financing tools</li>
                            <li>Creating incentives for builders to facilitate more housing, including PPPs , building schemes , from local councils up to state and federal jurisdictions</li>
                        </ul>

                        </div>

                        <div className='table-content'>
                        <p></p>
                        <p><span className='table-title'>Moderator:</span> ESV Tolu Dima-Okojie (Estate Surveyor & Valuer Kola Akomolede & Company) </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Jide Adekola (GMD/CEO, HomeWork Group Africa)</li>
                        <li>Oguche Agudah (CEO, Pension Funds Operators Association of Nigeria) </li>
                        <li>Richard Deniran (COO, MGE Realty) I.K. </li>
                        <li>Monsurat Mohammed 	(Head, Help-To-Own Mortage , Family Homes Fund) </li>
                        <li>Olukayode Odebiyi (Director, Property Development, Afriland Properties)
                        </li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                        <div className='table-title'>Plenary 3</div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        3:00 PM - 3:15 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Presentation : The role of service providers in the real estate processJude Ashang, Publisher, Trebnet Directory
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        3:15 PM - 4:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Digital Nomads- Are They Reshaping The Real Estate Business?
                        </div>
                        <div className='table-content'>
                        <div className='table-title'> Session Objective</div>
                        <p>
                        The concept of digital nomadism has evolved rapidly in the past few years, driven by technology and, of course, changing attitudes toward work. Digital nomads are basically known to be professionals from a wide range of industries who have embraced remote work as a lifestyle choice.
                        </p>

                        <p>
                        This has resulted in more professionals opting for location-independent work, thus a surge in demand for flexible living arrangements such as short-term rentals and co-living spaces. This trend is particularly pronounced in urban centres and tourist destinations where digital nomads are drawn to the vibrant communities and world-class amenities This diverse demographic is reshaping how we think about work and living arrangements.
                        </p>
                        <p>
                        This session seeks to discuss the popularity of digital nomadism and how it's transformed the real estate arena in Africa, its intricate relationship between the rise of digital nomadism and its cascading effects on the real estate industry especially in the areas of:
                        </p>
                        
                        <ul>
                            <li>Property Types and Specifications</li>
                            <li>Construction Timelines, Modalities and Parameters</li>
                            <li>Property marketing and sales</li>
                            <li>Building/Property locations</li>
                        </ul>

                        </div>
                        <div className='table-content'>
                        <p><span className='table-title'>Moderator:</span>Onome Umukoro (Group Head , Marketing and Corporate Communication, Mixta Africa)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>John Oamen 	(Co-founder/CEO, Cutstruct Technology)</li>
                        <li>Gbenga Adigun (Founder & Co-CEO, Rebar Perfecta)</li>
                        <li>Sean Godoy	(Co-founder SA PropTech & PropTech Africa)</li>
                        <li>Wayne Berger (Founder & Co-founder, Ishack Ventures/Africa Prop-Tech Forum)</li>
                        <li>Olisa Umerah (CEO & Co-founder, Jointly PropTech Inc)</li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        4:00 PM - 4:45 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Exhibition Tour
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        4:45 PM - 5:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Closing Remarks 
                        </div>
                    </td>
                    </tr>
                 
                </tbody>
                </Table>
            </div>

            {/* day 2 */}

            <div className='day-2'>
            <h2 className='table-title'>Day 2 - Wednesday, September 18th, 2024</h2>

            <Table hover>
                <thead>
                    <tr>
                    <th colSpan={colTime}>Time</th>
                    <th colSpan={colNum}>Activities</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        9:00 AM - 9:45 AM
                        </div>
                        </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Registration / Guest Arrival
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        9:45 AM - 9:55 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Welcome Address
                        </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        9:55 AM - 10:10 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Guest Speaker Speech
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:10 AM - 10:25 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Keynote Address 2: Akin Opatola, President Fiabci Nigeria
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        10:25 AM - 10:45 AM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Sponsor Presentation:  MIXTA Africa (Arc Victoria Oshinusi)
                        </div>
                    </td>
                    </tr>

                    <tr>
                        <td colSpan={2}>
                            <div className='table-title'>
                            Plenary 1
                            </div>
                        </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                    <div className='table-time'>
                    10:45 AM – 11:15 AM
                    </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Building Resilience To Manage Risk & Climate Change  In The Construction Industry
                        </div>

                        <div className='table-content'>
                        <div className='table-title'> Session Objective</div>
                        <p>
                        Unpredictable weather affects construction timelines and budgets. Research shows that each year, adverse weather events such as delay 45% of construction projects globally, costing billions of amounts in additional expense and lost revenue
                        </p>

                        <p>
                        Extreme climate events are becoming more frequent, and by 2030, there could be four times the amount of heatwaves, twice the amount of droughts, and extreme rainstorms could rise by 50%, according to the Intergovernmental Panel on Climate Change. Furthermore, the severity of these events is also on the rise. While extreme events have increased more than five times from 1970 to 2019, the cost of extreme global events has increased nearly eight times in that time, according to data from the World Meteorological Organization (WMO). This equates to cost per event increasing nearly 77%, inflation-adjusted, over the past five decades.
                        </p>
                        <p>
                        As construction projects become more audacious in scale and geography, and the number of climate-related disasters increases, there is a demand for more intelligence around climate risk and how it can be managed. Additionally, construction companies may face unknown climate change-related exposures in their supply chains. Physical damage from physical risk events to suppliers’ locations, public infrastructure, and transportation networks can lead to long delays and impact business revenues and project delivery schedules.
                        </p>

                        <p>
                        To retain a competitive edge and to remain able to operate in these conditions, it is recommended that contractors take action to understand, characterise, and address these risks and improve resilience. This session will discuss more topics as regards these challenges ranging from:  
                        </p>
                        
                        <ul>
                            <li>What are the implications of climate change and extreme weather on large-scale infrastructure construction projects? </li>
                            <li>What are the implications for contractors?</li>
                            <li>The role of insurance firms in construction risk management- anyone in particular? </li>
                            <li>How to manage these risks and gain long term Climate change risk free projects</li>
                            <li>The role of government agencies in sustainable buildings - any one in view?</li>
                        </ul>

                        </div>
                        <div className='table-content'>
                        <p><span className='table-title'>Moderator:</span> Dolapo Omidire (Founder, Estate Intel) </p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Adeola Olusodo (Executive Director , Joint Venture & Business Directorate, LSDPC) </li>
                        <li>Folajomi Ibrahim (Founder , Cogent Properties)</li>
                        <li>Dr. Sanmi Olowosile (Founder, Sustainable Green Environment Initiative)</li>
                        <li>Greg Truen (Principal, SAOTA) </li>
                        <li>Chukwuka Harrison OKOYE (CEO, FACIBUS Housing)</li>
                        <li>Osarogie Edo-Osagie (Design Associate, Mixta Africa) </li>
                        
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        11:15 AM - 2:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Exhibition Tour / Networking Break / Master Class / Presentations
                        </div>
                        <div className='table-content'>
                        
                        <ul>
                            <li>Mastering Real Estate Finance: Accounting and Tax Strategies for Nigerian Developers - Bayode Agbi, MD/CEO Pillar Craft Accounting</li>
                            <li>Real Estate Marketing - ALEPH</li>
                            <li>Data Science & AI - European Business Institute (13 weeks post event course) </li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={2}>
                    <div className='table-title'>
                        Plenary 3
                    </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:00 PM - 2:45 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Regulations, Policies: Impact on Real Estate Projects, How To Adapt
                        </div>

                        <div className='table-content'>
                        <div className='table-title'> Session Objective</div>
                        <p>
                        How do regulatory changes affect your real estate business? In a field where economic shifts, societal demands, and technological advancements drive continuous updates, these changes significantly impact property developers, property owners, and real estate professionals. Government policies and regulations also have a sign toll on the industry. From interest rates and tax incentives to zoning laws and development restrictions, the decisions made by policymakers can significantly shape the trajectory of the real estate market. Adapting to and staying informed about these regulatory changes is crucial for maintaining compliance and achieving success in the market.
                        </p>

                        <p>
                        This session seeks to give more insights on this topic through discussion on the following areas:
                        </p>
                        
                        <ul>
                            <li>Understanding the Policy-Real Estate Dynamics in Nigeria </li>
                            <li>Monetary and Fiscal Policies</li>
                            <li>Land Use and Zoning Regulations</li>
                            <li>Green Building Standards, Data Protection and Privacy Laws</li>
                            <li>Building Codes and Construction Regulation</li>
                            <li>Regulatory Impacts Across Real Estate Sectors: Residential, Commercial , Industrial, Rental properties 
                            </li>
                            <li>Industry Advocacy ( Real Estate Associations and Policy Making)</li>
                            <li>What are the Implications for Real Estate Investors and Developers?</li>
                        </ul>

                        </div>


                        <div className='table-content'>
                        <p><span className='table-title'>Moderator:</span> Habinuchi Owhondah ANIVS, RSV (Country Manager, CBRE|Excellerate)</p>
                        <h3 className='table-title'>Panelist</h3>
                        <ul>
                        <li>Tari Taylur (Senior Special Adviser To The Lagos State Governor on Environment)</li>
                        <li>TPL, Kunle Salami (Director of Physical Planning, Lagos State)</li>
                        <li>Ayuli Jemide (Founder and Lead Partner, Detail Commercial Solicitors) </li>
                        <li>Odunayo Ojo (CEO , UPDC PLC)</li>
                        <li>Igbuan Okaisabor (CEO, Construction Kaiser)</li>
                        <li>Olubisi Shaola (Founder , Finn Grey Projects)</li>
                        </ul>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        2:45 PM - 4:45 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                       Awards
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        4:45 PM - 5:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Closing Remarks
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td colSpan={colTime}>
                        <div className='table-time'>
                        5:00 PM - 7:00 PM
                        </div>
                    </td>
                    <td colSpan={colNum}>
                        <div className='table-title'>
                        Dinner (Strictly by Invitation)
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </div>

            <VoteBanner />
            
            </div>
        </div> 
     );
}
 
export default Agenda;