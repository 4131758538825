import './homepage.css';
import Header from '../header/Header';
import Topics from '../topics/Topics';
import speakersData from '../../data';
import SpeakerCard from '../speakers/SpeakerCard';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TicketBanner from '../ticketbanner/TicketBanner';
import OrganisingPartner from '../organiser/OrganisingPartner';
import VoteBanner from '../votebanner/VoteBanner';




const Homepage = () => {
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
        // Load speakers data when the component mounts
        setSpeakers(speakersData);
    }, []);

    const featuredSpeakers = speakers.slice(0, 6);

    return ( 
        <main>
            <Header />
            <OrganisingPartner />
            <VoteBanner />
            <Topics />
            <TicketBanner />
            <div className="featured-speaker-wrapper container">
                <h2>Speakers at REDA 2024</h2>
            <div className="featured-speaker-group">
                
            {featuredSpeakers.map((speaker) => (
                <SpeakerCard
                    key={speaker.id}
                    id={speaker.id}
                    name={speaker.name}
                    title={speaker.title}
                    position={speaker.position}
                    company={speaker.company}
                    imageUrl={speaker.imageUrl}
                    about={speaker.about}
                    status={speaker.status}
                />
            ))}
            </div>
            <Link to = '/speakers-list' className='more-speaker-button' >View All Speakers</Link>

            <VoteBanner />

            </div>
            

        </main>
    );
}
 
export default Homepage;