import './navbar.css';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <nav className='container'>
      <input type="checkbox" id="nav-check" />
      
        <Link to='/' className="main-logo">
          REDA24
        </Link>
      
      <div className="nav-btn">
        <label for="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      
      <div class="link-group">
        <Link to = '/about' className='link-item'  >About</Link>
        <Link to = '/speakers-list' className='link-item' >Speakers</Link>
        <Link to = '/agenda' className='link-item' >Agenda</Link>
        <Link to = '/tickets' className='link-item'> Tickets</Link>
        <Link to = '/award-categories' className='link-item'> Award Categories</Link>
        <Link to = '/gallery' className='link-item'> Gallery</Link>
      </div>
    </nav>
  
  );
}
 
export default NavBar;
