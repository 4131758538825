import { Link } from 'react-router-dom';
import votingbanner from '../../images/reda24-vote-here.jpg';
import './votebanner.css';

const VoteBanner = () => {
    return (
        <Link to='https://forms.gle/Cc8n4z7zcePQCQSU8' className="vote-banner">
            <img src={votingbanner} alt="" className="img-fluid" />
        </Link>
      );
}
 
export default VoteBanner;