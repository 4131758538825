import './organiser.css';
import lsdpc  from '../../images/lsdpc-logo.png';
import mediapartner  from '../../images/media-partner-logo.png';
import participatingBrand  from '../../images/participating-brands.png';



const OrganisingPartner = () => {
    return ( 
        <div className='container orgainising-logo'>
            <h2 className='logo-section-title'>Partners</h2>

            <div className='partner-group'>
                <div>Organising partner</div>
                <img src = {lsdpc} alt="lsdpc" className="logo-item" />
            </div>

            <div className='partner-group'>
                <div>Media partners</div>
                <img src = {mediapartner} alt=" media partners" className="logo-item" />
            </div>

            <div className='partner-group'>
                <div>Participating brands</div>
                <img src = {participatingBrand} alt=" media partners" className="logo-item" />
            </div>

        </div>
    );
}
 
export default OrganisingPartner;