import { Link } from 'react-router-dom';
import './topics.css';

const Topics = () => {
    return ( 
        <div className="topic container" id="topics"> 
            <h2> Reda 2024 Topics </h2>

            <div className="topic-group">
                <div className="topic-item"> The role of real estate in institutional and investment portfolios - Is there any appeal?  </div>
                <div className="topic-item"> The constant evolution and pressures of Real Estate industry: Some intriguing insights on where the market is heading </div>
                <div className="topic-item"> Housing, cost-of-living and social impact </div>
                <div className="topic-item"> Digital nomads: are they reshaping the real estate business? </div>
                <div className="topic-item"> How design is responding to the toughest challenges facing cities </div>
                <div className="topic-item"> Building resilience to manage risk and climate change </div>
                <div className="topic-item"> Regulations , Policies : impact on real estate projects - how to adapt. </div>
            </div>
            <Link to = '/register' className='register-button'>REGISTER</Link>
        </div>
     );
}
 
export default Topics;