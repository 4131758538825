import './subtheme.css';
import  icon1  from '../../images/subtheme/icon-1.png';
import  icon2  from '../../images/subtheme/icon-2.png';
import  icon3  from '../../images/subtheme/icon-3.png';
import  icon4  from '../../images/subtheme/icon-4.png';
import  icon5  from '../../images/subtheme/icon-5.png';
import  icon6  from '../../images/subtheme/icon-6.png';
import  icon7  from '../../images/subtheme/icon-7.png';


const Subtheme = () => {
    return ( 
        <div className="subtheme-group">
            <div className="subtheme-item">
                <img src={ icon1 } alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Residential</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon2 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Hospitality/Retail</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon3 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Construction</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon4 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Sustainability</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon5 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Proptech</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon6 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Impact of Regulators & Associations</div>
            </div>
            <div className="subtheme-item">
                <img src={ icon7 }  alt=""  className="subtheme-icon" />
                <div className="subtheme-title">Industry and Sustainability in Focus</div>
            </div>
        </div>
    );
}
 
export default Subtheme;