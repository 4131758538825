import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar/Nav";
import Homepage from "./components/homepage/Homepage";
import Footer from "./components/footer/Footer";
import AboutUs from "./components/aboutus/About";
import Speakers from './components/speakers/Speakers';
import Ticket from './components/ticket/Ticket';
import RegisterForm from './components/register-form/RegisterForm';
import AwardList from './components/award/AwardList';
import NoPage from "./components/nopage/NoPage";
import GalleryList from './components/gallery/GalleryList';
import Agenda from './components/agenda/Agenda';




function App() {
  return (
    <BrowserRouter className="App">
      <NavBar />
      <Routes className='reda-wrapper'>
        <Route index = '/' element = {<Homepage />} />
        <Route path="about" element = {<AboutUs />} />
        <Route path="speakers-list" element = {<Speakers />} />
        <Route path="agenda" element = {<Agenda />} />
        <Route path="tickets" element = {<Ticket />} />
        <Route path="register" element = {<RegisterForm />} />
        <Route path="award-categories" element = {<AwardList />} />
        <Route path="gallery" element = {<GalleryList />} />
        
        <Route path="*" element = {<NoPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
