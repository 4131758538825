import './ticketbanner.css';
import ticketbanner from '../../images/ticketbanner.jpg';
import { Link } from 'react-router-dom';


const TicketBanner = () => {
    return ( 
        <Link to='/tickets' className='ticketbanner container'>
            <img src={ticketbanner} alt='ticket banner' className='img-fluid' />
        </Link>

     );
}
 
export default TicketBanner;