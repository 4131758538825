import './registerform.css';
import React, {useState, useRef} from "react";
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
    const formRef = useRef(null)
    // const scriptURL = 'https://script.google.com/macros/s/AKfycbxI1oQU43FO1mSNTLtPMlG3osYVeM-BeQgmujkVhSiKn2gvR40OsU3kBhNmrK3WpMMk0w/exec'
    const scriptURL = 'https://script.google.com/macros/s/AKfycbx9khlEEcIcA86ApnqHwW_RyXLvF3X6GT9cjHtrecb1NkzdTgaUkT981BfCYvKRF8v9Pw/exec'
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [formClass, setFormClass] = useState('')
    const navigate = useNavigate()


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptURL, {
            method: 'POST',
            body: new FormData(formRef.current),
        }).then(res => {
            setMessage('Registration Successful!')
            setFormClass('success-message')
            navigate('/tickets')

            // setTimeout(() => {
            //     setFormClass('')
            //     setMessage('')
            //     }, 5000);

            // setLoading(false)
            // formRef.current.reset()
        })
    }

    return ( 
        <section className="form-section"  id="forms">
            <div className='section-header'>
            <h2>Register for REDA 2024</h2>
            </div>
            <form name="submit-to-google-sheet" className='container' ref={ formRef } onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type="text" placeholder="First Name" name="firstname" className="form-firstname" required />
                    <input type="text" placeholder="Last Name" name="lastname" className="form-lastname" required />
                    <input type="email" placeholder="Email Address" name="email" className="form-email" required />
                    <input type="tel" placeholder="Phone Number" name="phone" className="form-phone" required />
                    <input type="text" placeholder="Company Name" name="company" className="form-company" required />
                    <input type="text" placeholder="Position in Company" name="position" className="form-position" required />
                    <div className='radio-title'>
                        <div>Would you like to get a paid ticket?</div>
                    <label class="container-radio">Yes
                    <input type="radio" checked="checked" name="ticket" value="Yes" required />
                    <span class="checkmark"></span>
                    </label>
                    <label class="container-radio">No
                    <input type="radio" name="ticket" value="No" required />
                    <span class="checkmark"></span>
                    </label>
                    </div>
                    <input type="submit" className="form-submit" value={loading ? "Registering..." : "Submit"} />
                    <p className={ formClass }> { message } </p>
                </div>
                
            </form>
            
            
        </section>
    );
}
 
export default RegisterForm;