const galleryData  = [

    {
        id : 1,
        galleryImageUrl : "gallery1.jpg",
    },
    {
        id : 2,
        galleryImageUrl : "gallery2.jpg",
    },
    {
        id : 3,
        galleryImageUrl : "gallery3.jpg",
    },
    {
        id : 4,
        galleryImageUrl : "gallery4.jpg",
    },
    {
        id : 5,
        galleryImageUrl : "gallery5.jpg",
    },
    {
        id : 6,
        galleryImageUrl : "gallery6.jpg",
    },
    {
        id : 7,
        galleryImageUrl : "gallery7.jpg",
    },  {
        id : 8,
        galleryImageUrl : "gallery8.jpg",
    },
    {
        id : 9,
        galleryImageUrl : "gallery9.jpg",
    },
    {
        id : 10,
        galleryImageUrl : "gallery10.jpg",
    },
    {
        id : 11,
        galleryImageUrl : "gallery11.jpg",
    },
    {
        id : 12,
        galleryImageUrl : "gallery12.jpg",
    },
    {
        id : 13,
        galleryImageUrl : "gallery13.jpg",
    },
    {
        id : 14,
        galleryImageUrl : "gallery14.jpg",
    },
    {
        id : 15,
        galleryImageUrl : "gallery15.jpg",
    },
    {
        id : 16,
        galleryImageUrl : "gallery16.jpg",
    },
    {
        id : 17,
        galleryImageUrl : "gallery17.jpg",
    },  {
        id : 18,
        galleryImageUrl : "gallery18.jpg",
    },
    {
        id : 19,
        galleryImageUrl : "gallery19.jpg",
    },
    {
        id : 20,
        galleryImageUrl : "gallery20.jpg",
    },
    {
        id : 21,
        galleryImageUrl : "gallery21.jpg",
    },
    {
        id : 22,
        galleryImageUrl : "gallery22.jpg",
    },
    {
        id : 23,
        galleryImageUrl : "gallery23.jpg",
    },
    {
        id : 24,
        galleryImageUrl : "gallery24.jpg",
    },
    {
        id : 25,
        galleryImageUrl : "gallery25.jpg",
    },
    {
        id : 26,
        galleryImageUrl : "gallery26.jpg",
    },
    {
        id : 27,
        galleryImageUrl : "gallery27.jpg",
    },  {
        id : 28,
        galleryImageUrl : "gallery28.jpg",
    },
    {
        id : 29,
        galleryImageUrl : "gallery29.jpg",
    },
    {
        id : 30,
        galleryImageUrl : "gallery30.jpg",
    },

]

export default galleryData;